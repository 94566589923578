import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters(['meterReadingDate']),
    defaultMonthDate() {
      let dateFilterLabel = moment()
      const day = moment().format('D')
      if (parseInt(day, 0) < parseInt(this.meterReadingDate, 0)) {
        dateFilterLabel = moment().subtract(1, 'months')
      }

      return dateFilterLabel
    },
  },
}
