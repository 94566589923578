<template>
  <div>
    <v-card-title class="pb-0 pt-2 green darken-4">
      <p class="body-1 font-weight-medium">
        Year
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          cols="6"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.year"
                outlined
                label="Year"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.year"
              :active-picker.sync="activePicker"
              :max="new Date().getFullYear().toString()"
              min="2020"
              no-title
              @change="onYearChange"
              @click:year="onYearChange"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      menu: false,
      activePicker: null,
      dateFilterLabel: '',
      filters: {
        year: '',
      },
    }
  },
  watch: {
    menu() {
      setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    this.dateFilterLabel = moment().format('YYYY')
    this.filters.year = moment().format('YYYY')
    this.onYearChange(this.filters.year)
  },
  methods: {
    onYearChange(value) {
      const data = {
        filters: value,
        label: value,
      }
      this.menu = false
      this.filters.year = value.toString()
      this.$emit('year-change', data)
    },
  },

}
</script>
