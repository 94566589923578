<template>
  <div>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Internet Connection Type
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="ml-2 pt-0">
      <v-row class="pt-0">
        <v-col
          cols="12"
          md="6"
        >
          <v-checkbox
            v-model="filters.staticIp"
            label="Static IP"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-checkbox
            v-model="filters.pppoe"
            label="PPPoE"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Internet Plan Status
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="ml-2 pt-0">
      <v-row class="pt-0">
        <v-col
          cols="12"
          md="6"
        >
          <v-checkbox
            v-model="filters.activePlan"
            label="Active Plan"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-checkbox
            v-model="filters.expiredPlan"
            label="Expired Plan"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Internet Access Status
      </p>
    </v-card-title><v-divider></v-divider>
    <v-card-text class="ml-2 pt-0">
      <v-row class="pt-0">
        <v-col
          cols="12"
          md="6"
        >
          <v-checkbox
            v-model="filters.internetAccess"
            label="Internet Access"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-checkbox
            v-model="filters.noInternetAccess"
            label="No Internet Access"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Debt Status
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="ml-2 pt-0">
      <v-row class="pt-0">
        <v-col
          cols="6"
          md="6"
        >
          <v-checkbox
            v-model="filters.debt"
            label="Debt"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <v-checkbox
            v-model="filters.noDebt"
            label="No Debt"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: {
        activePlan: false,
        expiredPlan: false,
        internetAccess: false,
        noInternetAccess: false,
        debt: false,
        noDebt: false,
      },
      filterLabel: 'all customers',
    }
  },
  mounted() {
    if (this.initialFilters) {
      this.filters = this.initialFilters
    }
    this.onCheckboxChange()
  },
  methods: {
    onCheckboxChange() {
      this.generateFilterLabel()
      const data = {
        filters: this.filters,
        label: this.filterLabel,
      }
      this.$emit('status-change', data)
    },
    generateFilterLabel() {
      const labels = []
      if (this.filters.activePlan) {
        labels.push('active plan')
      }
      if (this.filters.expiredPlan) {
        labels.push('expired plan')
      }
      if (this.filters.internetAccess) {
        labels.push('internet access')
      }
      if (this.filters.noInternetAccess) {
        labels.push('no internet access')
      }
      if (this.filters.debt) {
        labels.push('debt')
      }
      if (this.filters.noDebt) {
        labels.push('no debt')
      }
      if (this.filters.staticIp) {
        labels.push('static IP') // New label
      }
      if (this.filters.pppoe) {
        labels.push('PPPoE') // New label
      }
      if (labels.length === 0) {
        this.filterLabel = 'all customers'

        return
      }
      this.filterLabel = labels[0]
      if (labels.length === 2) {
        this.filterLabel = `${this.filterLabel} and ${labels[1]}`
      }
      if (labels.length === 6) {
        this.filterLabel = `${this.filterLabel}, ${labels[1]} and ${labels[2]}`
      }
    },
  },

}
</script>

<style>

</style>
