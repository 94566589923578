var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoices-table"},[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isInvoicesRefreshing},on:{"click":_vm.refreshInvoices}})],1),_c('v-col',{staticClass:"col-md-6 text-md-right"},[_c('table-filter',{attrs:{"table-name":'invoices',"show-date-filter":true,"filters":_vm.tableFilters,"default-date-picker-date":_vm.defaultDatePickerDate},on:{"filter":_vm.applyFilters,"filterLabelChange":_vm.onFilterLabelChange}})],1),_c('v-col',{staticClass:"col-md-3 text-md-right"},[_c('export-button',{attrs:{"title":_vm.exportTitle,"headers":_vm.exportHeaders,"data-endpoint":_vm.constructCompleteInvoicesUrl,"orientation":'landscape'}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.filteredSearchFilters,"active-filter":_vm.searchColumn},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"loading":_vm.isInvoicesLoading,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.invoice_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice_number)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDay")(item.created_at))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDay")(item.due_date))+" ")]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" Ksh "+_vm._s(_vm._f("formatCurrency")(item.total_amount))+" ")]}},{key:"item.status_description",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getChipColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","loading":_vm.viewingInvoicesLoading[item.id],"disabled":_vm.viewingInvoicesLoading[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.viewInvoice(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._b({},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View pdf")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":"","loading":_vm.loadingInvoices[item.id],"disabled":_vm.loadingInvoices[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.downloadInvoice(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download pdf")])])]}}])},[_vm._v("> ")]),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }